<template>
    <ProfilePreload v-if="props.preload" />
    <div v-else class="basic_info">
        <div
            class="basic_info__row right"
            v-if="state.isOwner"
        >
            <a
                target="_blank"
                :href="config.signateProfile"
            >
                <button>
                    会員基本情報の更新はこちら
                </button>
            </a>
        </div>
        <div
            class="basic_info__row"
            v-if="state.isOwner"
        >
            <label >
                氏名
                <br />
                <span class="note">※公開されません</span>
            </label>
            <div class="content" v-bind:class="{ 'no-data': !state.userProfileInfo.family_name && !state.userProfileInfo.given_name }">
                <span v-if="state.userProfileInfo.family_name || state.userProfileInfo.given_name">
                    {{ state.userProfileInfo.family_name + ' ' + state.userProfileInfo.given_name }}
                </span>
                <span v-if="!state.userProfileInfo.family_name && !state.userProfileInfo.given_name">
                    情報がありません
                </span>
            </div>
            <a
                href="javascript:void(0);"
                class="icon-edit"
                @click="openEditModal('name')"
                v-if="state.isOwner"
            >
                <i
                    class="fal fa-edit"
                    aria-hidden="true"
                ></i>
            </a>
        </div>
        <div
            class="basic_info__row"
            v-if="state.isOwner"
        >
            <label >
                （氏名）カナ
                <br />
                <span class="note">※公開されません</span>
            </label>
            <div class="content" v-bind:class="{ 'no-data': !state.userProfileInfo.family_name_kana && !state.userProfileInfo.given_name_kana }">
                <span v-if="state.userProfileInfo.family_name_kana || state.userProfileInfo.given_name_kana">
                    {{ state.userProfileInfo.family_name_kana + ' ' + state.userProfileInfo.given_name_kana }}
                </span>
                <span v-if="!state.userProfileInfo.family_name_kana && !state.userProfileInfo.given_name_kana">
                    情報がありません
                </span>
            </div>
            <a
                href="javascript:void(0);"
                class="icon-edit"
                @click="openEditModal('name_kana')"
                v-if="state.isOwner"
            >
                <i
                    class="fal fa-edit"
                    aria-hidden="true"
                ></i>
            </a>
        </div>
        <div class="basic_info__row">
            <label >
                ニックネーム
            </label>
            <div class="content" v-bind:class="{ 'no-data': !state.userProfileInfo.nickname }">
                <span>
                    {{ state.userProfileInfo.nickname || "情報がありません" }}
                </span>
            </div>
        </div>
        <div
            class="basic_info__row"
            v-if="(!state.isOwner && state.userProfileInfo.is_public_prefecture) || state.isOwner"
        >
            <label >居住地</label>
            <div class="content" v-bind:class="{ 'no-data': !state.userProfileInfo.residence_prefecture_id }">
                <span v-if="state.userProfileInfo.residence_prefecture_id">{{ state.userProfileInfo.residence_prefecture_name }} {{ state.userProfileInfo.residence_city_name }}</span>
                <span v-else>情報がありません</span>
                <template
                    v-if="state.isOwner"
                >
                    <input
                        type="checkbox"
                        id="is_public_prefecture"
                        name="is_public_prefecture"
                        :checked="state.userProfileInfo.is_public_prefecture"
                        @change="updatePublicState()"
                    >
                    <label for="is_public_prefecture">公開する</label>
                </template>
            </div>
        </div>
        <!-- <div
            class="basic_info__row"
            v-if="(!state.isOwner && state.userProfileInfo.is_public_language) || state.isOwner"
        >
            <label >言語</label>
            <div class="content" v-bind:class="{ 'no-data': !state.userProfileInfo.language }">
                <span>
                    {{ state.userProfileInfo.language || "情報がありません" }}
                </span>
                <template
                    v-if="state.isOwner"
                >
                    <input
                        type="checkbox"
                        id="is_public_language"
                        name="is_public_language"
                        :checked="state.userProfileInfo.is_public_language"
                        @change="updatePublicState()"
                    >
                    <label for="is_public_language">公開する</label>
                </template>
            </div>
        </div> -->
         <!-- <div
            class="basic_info__row"
            v-if="(!state.isOwner && state.userProfileInfo.is_public_language) || state.isOwner"
        >
            <label >言語</label>
            <div class="content" v-bind:class="{ 'no-data': !state.userProfileInfo.language }">
                <span>
                    {{ state.userProfileInfo.language || "情報がありません" }}
                </span>
                <template
                    v-if="state.isOwner"
                >
                    <input
                        type="checkbox"
                        id="is_public_language"
                        name="is_public_language"
                        :checked="state.userProfileInfo.is_public_language"
                        @change="updatePublicState()"
                    >
                    <label for="is_public_language">公開する</label>
                </template>
            </div>
        </div> -->
        <div
            class="basic_info__row"
        >
            <label>
                学校名
                <br />
                <span class="note" v-if="state.isOwner">※入力した場合は公開されます</span>
            </label>
            <div class="content" v-bind:class="{ 'no-data': !state.userProfileInfo.alma_mater }">
                <span>
                    {{ state.userProfileInfo.alma_mater || "情報がありません" }}
                </span>
            </div>
            <a
                href="javascript:void(0);"
                class="icon-edit"
                @click="openEditModal('alma_mater')"
                v-if="state.isOwner"
            >
                <i
                    class="fal fa-edit"
                    aria-hidden="true"
                ></i>
            </a>
        </div>
        <div
            class="basic_info__row"
        >
            <label>
                学部・研究科名
                <br />
                <span class="note" v-if="state.isOwner">※入力した場合は公開されます</span>
            </label>
            <div class="content" v-bind:class="{ 'no-data': !state.userProfileInfo.academy_department }">
                <span>
                    {{ state.userProfileInfo.academy_department || "情報がありません" }}
                </span>
            </div>
            <a
                href="javascript:void(0);"
                class="icon-edit"
                @click="openEditModal('academy_department')"
                v-if="state.isOwner"
            >
                <i
                    class="fal fa-edit"
                    aria-hidden="true"
                ></i>
            </a>
        </div>
      <div
          class="basic_info__row"
      >
        <label>
          卒業年度
          <br />
          <span class="note" v-if="state.isOwner">※入力した場合は公開されます</span>
        </label>
        <div class="content" v-bind:class="{ 'no-data': !state.userProfileInfo.graduation_year }">
                <span>
                    {{ graduationYear() }}
                </span>
        </div>
        <a
            href="javascript:void(0);"
            class="icon-edit"
            @click="openEditModal('graduation_year')"
            v-if="state.isOwner"
        >
          <i
              class="fal fa-edit"
              aria-hidden="true"
          ></i>
        </a>
      </div>
        <!-- <div　BGV-691 SSO改修に伴いコメントアウト
            class="basic_info__row"
            v-if="(!state.isOwner && state.userProfileInfo.is_public_graduation) || state.isOwner"
        >
            <label >卒業区分</label>
            <div class="content" v-bind:class="{ 'no-data': !state.userProfileInfo.graduation.match(/[0-9]/g) }">
                <span v-if="state.userProfileInfo.graduation.match(/[0-9]/g)">
                  {{ state.userProfileInfo.graduation }}
                </span>
                <span v-else>情報がありません</span>
                <template
                    v-if="state.isOwner"
                >
                    <input
                        type="checkbox"
                        id="is_public_graduation"
                        name="is_public_graduation"
                        :checked="state.userProfileInfo.is_public_graduation"
                        @change="updatePublicState()"
                    >
                    <label for="is_public_graduation">公開する</label>
                </template>
            </div>
        </div> -->
        <!-- <div
            class="basic_info__row"
            v-if="(!state.isOwner && state.userProfileInfo.is_public_society_type) || state.isOwner"
        >
            <label >社会人 / 学生</label>
            <div class="content" v-bind:class="{ 'no-data': !state.userProfileInfo.society_type }">
                <span>
                    {{ state.userProfileInfo.society_type || "情報がありません" }}
                </span>
                <template
                    v-if="state.isOwner"
                >
                    <input
                        type="checkbox"
                        id="is_public_society_type"
                        name="is_public_society_type"
                        :checked="state.userProfileInfo.is_public_society_type"
                        @change="updatePublicState()"
                    >
                    <label for="is_public_society_type">公開する</label>
                </template>
            </div>
        </div> -->
        <div
            class="basic_info__row"
            v-if="state.isOwner"
        >
            <label >
                勤務先 
                <br />
                <span class="note">※公開されません<br>※現在の勤務先がある場合は必ず入力してください(協働企業側が、競合企業に所属する受講生とのマッチングはNGと判断する場合があるため)</span>
            </label>
            <div class="content-wrapper">
                <div
                    class="content"
                    :class="{
                      'no-data':
                       !state.userProfileInfo.organization_name
                       && !state.userProfileInfo.organization_name_2
                       && !state.userProfileInfo.organization_name_3
                }">
                    <span v-if="
                       !state.userProfileInfo.organization_name
                       && !state.userProfileInfo.organization_name_2
                       && !state.userProfileInfo.organization_name_3
                    " >
                      情報がありません
                    </span>
                    <div v-else>
                        <div v-if="state.userProfileInfo.organization_name ">
                            {{ state.userProfileInfo.organization_name }}
                        </div>
                        <div
                            v-if="state.userProfileInfo.corporate_number === state.corporateNumberNoWorkPlace
                             && state.userProfileInfo.reason_no_work_place "
                        >
                            {{ state.userProfileInfo.reason_no_work_place }}
                        </div>
                        <div v-if="state.userProfileInfo.organization_name_2">
                            {{ state.userProfileInfo.organization_name_2 }}
                        </div>
                        <div
                            v-if="state.userProfileInfo.corporate_number_2 === state.corporateNumberNoWorkPlace
                             && state.userProfileInfo.reason_no_work_place "
                        >
                          {{ state.userProfileInfo.reason_no_work_place }}
                        </div>
                        <div v-if="state.userProfileInfo.organization_name_3 ">
                            {{ state.userProfileInfo.organization_name_3 }}
                        </div>
                        <div
                            v-if="state.userProfileInfo.corporate_number_3 === state.corporateNumberNoWorkPlace
                             && state.userProfileInfo.reason_no_work_place "
                        >
                          {{ state.userProfileInfo.reason_no_work_place }}
                        </div>
                    </div>
                </div>
                <a
                    href="javascript:void(0);"
                    class="icon-edit"
                    @click="openEditModal('work_place')"
                    v-if="state.isOwner"
                >
                    <i
                        class="fal fa-edit"
                        aria-hidden="true"
                    ></i>
                </a>
            </div>
        </div>
        <div class="basic_info__row">
            <label >自己紹介</label>
            <div class="content" v-bind:class="{ 'no-data': !state.userProfileInfo.profile_description }">
                <span v-html="StringUtils.urlifyString(state.userProfileInfo.profile_description) || '情報がありません'"></span>
            </div>
            <a
                href="javascript:void(0);"
                class="icon-edit"
                @click="openEditModal('profile_description')"
                v-if="state.isOwner"
            >
                <i
                    class="fal fa-edit"
                    aria-hidden="true"
                ></i>
            </a>
        </div>
        <div class="basic_info__row">
            <label >PR / 特技</label>
            <div class="content" v-bind:class="{ 'no-data': !state.userProfileInfo.public_relations_description }">
                <span v-html="StringUtils.urlifyString(state.userProfileInfo.public_relations_description) || '情報がありません'"></span>
            </div>
            <a
                href="javascript:void(0);"
                class="icon-edit"
                @click="openEditModal('public_relations_description')"
                v-if="state.isOwner"
            >
                <i
                    class="fal fa-edit"
                    aria-hidden="true"
                ></i>
            </a>
        </div>
    </div>

    <UserResumeUploadPopup
        v-if="state.showUploadPopup"
        :dataUrl="state.userProfileInfo?.pr_file_url"
        @complete="handleResumeUpdate"
        @close="state.showUploadPopup = false"        
    />

    <UserProfileEditInfoPopup
        v-if="state.showEditPopup"
        :field="state.editField"
        :title="state.editTitle"
        :serializeForm="state.serializeForm"
        @close="state.showEditPopup = false;"
        :societyType="state.userProfileInfo.society_type"
    />
</template>
<script lang="ts" src="@/presentation/views/user/profile/info/Info.ts" />
<link type="scss" scoped src="@/presentation/views/user/profile/info/Info.scss" />
