import LanguageUtil from "@/commons/LanguageUtil";
import { cloneDeep } from 'lodash';
import { defineComponent, reactive, onMounted, computed, watch } from "vue";
import { useStore } from "vuex";
import { useForm, useField } from "vee-validate";
import * as yup from "yup";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import SelectOrganization from "@/presentation/components/select-organization/SelectOrganization.vue";
import UserInteractor from "@/domain/usecases/UserInteractor";
import DIContainer from "@/core/DIContainer";
import * as agh from "agh.sprintf";
import Constant from "@/commons/Constant";
import Input from "@/presentation/components/input/Input.vue";
import MasterInteractor from "@/domain/usecases/MasterInteractor";
import MyConfig from "@/_config";
export default defineComponent({
    name: "UserProfileEditInfoPopup",
    components: {
        Textarea: Textarea,
        ErrorMessage: ErrorMessage,
        Input: Input,
        SelectOrganization: SelectOrganization,
    },
    props: [
        "field",
        "title",
        "serializeForm",
        "societyType"
    ],
    setup: function (props, _a) {
        var emit = _a.emit;
        var store = useStore();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            field: props.field,
            title: props.title,
            isSubmitting: false,
            errorUpdateUserProfileInfoMessage: null,
            errorValidateMessage: {},
            userProfileInfo: store.state.userProfileInfo,
            loadingDataDropdown: false,
            listIndustry: [],
            listOccupation: [],
            listAvailableDatetime: [],
            corporateNumberNoWorkPlace: MyConfig.corporateNumberNoWorkPlace,
        });
        var userInteractor = DIContainer.instance.get(UserInteractor);
        var masterInteractor = DIContainer.instance.get(MasterInteractor);
        var _b = useForm({
            initialValues: {
                family_name: state.userProfileInfo.family_name,
                given_name: state.userProfileInfo.given_name,
                family_name_kana: state.userProfileInfo.family_name_kana,
                given_name_kana: state.userProfileInfo.given_name_kana,
                industries: cloneDeep(state.userProfileInfo.industries),
                industry_optional: state.userProfileInfo.industry_optional,
                occupations: cloneDeep(state.userProfileInfo.occupations),
                alma_mater: state.userProfileInfo.alma_mater,
                academy_department: state.userProfileInfo.academy_department,
                graduation_year: state.userProfileInfo.graduation_year,
                organization_name: state.userProfileInfo.organization_name,
                corporate_number: state.userProfileInfo.corporate_number,
                organization_name_2: state.userProfileInfo.organization_name_2,
                corporate_number_2: state.userProfileInfo.corporate_number_2,
                organization_name_3: state.userProfileInfo.organization_name_3,
                corporate_number_3: state.userProfileInfo.corporate_number_3,
                reason_no_work_place: state.userProfileInfo.reason_no_work_place,
                profile_description: state.userProfileInfo.profile_description,
                public_relations_description: state.userProfileInfo.public_relations_description,
                society_type: props.societyType
            }
        }), isSubmitting = _b.isSubmitting, values = _b.values, errors = _b.errors, handleSubmit = _b.handleSubmit, setValues = _b.setValues;
        var family_name = useField("family_name", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var given_name = useField("given_name", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var family_name_kana = useField("family_name_kana", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))
            .matches(Constant.KANA_PATTERN, state.aLang.validation_format_kana)
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var given_name_kana = useField("given_name_kana", yup
            .string()
            .required(state.aLang.validation_required)
            .max(64, agh.sprintf(state.aLang.validation_max_length, 64))
            .matches(Constant.KANA_PATTERN, state.aLang.validation_format_kana)
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var industries = useField("industries", yup
            .array()
            .nullable()).value;
        var industry_optional = useField("industry_optional", yup
            .string()
            .nullable()
            .max(10000, agh.sprintf(state.aLang.validation_max_length, 10000))
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var occupations = useField("occupations", yup
            .array()
            .nullable()).value;
        var organization_name = useField("organization_name", function (value) {
            if ((!value || !value.toString().trim()) && props.societyType === '社会人') {
                return state.aLang.validation_required;
            }
            return true;
        }).value;
        var corporate_number = useField("corporate_number", yup
            .string().nullable()).value;
        var organization_name_2 = useField("organization_name_2", yup.string().nullable()).value;
        var corporate_number_2 = useField("corporate_number_2", yup.string().nullable()).value;
        var organization_name_3 = useField("organization_name_3", yup.string().nullable()).value;
        var corporate_number_3 = useField("corporate_number_3", yup.string().nullable()).value;
        var reason_no_work_place = useField("reason_no_work_place", yup.string()
            .nullable()
            .max(255, agh.sprintf(state.aLang.validation_max_length, 255))).value;
        var profile_description = useField("profile_description", yup
            .string()
            .nullable()
            .max(10000, agh.sprintf(state.aLang.validation_max_length, 10000))
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var public_relations_description = useField("public_relations_description", yup
            .string()
            .nullable()
            .max(10000, agh.sprintf(state.aLang.validation_max_length, 10000))
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var alma_mater = useField("alma_mater", yup
            .string()
            .max(255, agh.sprintf(state.aLang.validation_max_length, 255))
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var academy_department = useField("academy_department", yup
            .string()
            .max(255, agh.sprintf(state.aLang.validation_max_length, 255))
            .transform(function (value, originalValue) {
            return originalValue.trim() === "" ? "" : value;
        })).value;
        var graduation_year = useField("graduation_year").value;
        useField("society_type", yup.string().nullable());
        var onSubmit = handleSubmit(function () {
            state.isSubmitting = true;
            return userInteractor
                .updateUserProfile(Object.assign(props.serializeForm, values))
                .then(function (result) {
                store.commit("setUserProfileData", Object.assign(state.userProfileInfo, values));
                state.isSubmitting = false;
                emit('close');
            }, function (error) {
                if (error.validation_errors) {
                    state.errorValidateMessage = error.validation_errors;
                }
                state.isSubmitting = false;
                state.errorUpdateUserProfileInfoMessage = error.message;
            });
        });
        var nowYear = new Date().getFullYear();
        function onUnselectedOrganization(organizationIndex) {
            switch (organizationIndex) {
                case 1: {
                    setValues({
                        corporate_number: null,
                        organization_name: null,
                    });
                    break;
                }
                case 2: {
                    setValues({
                        corporate_number_2: null,
                        organization_name_2: null,
                    });
                    break;
                }
                case 3: {
                    setValues({
                        corporate_number_3: null,
                        organization_name_3: null,
                    });
                    break;
                }
            }
        }
        function onSelectOrganization(organization, organizationIndex) {
            switch (organizationIndex) {
                case 1: {
                    setValues({
                        corporate_number: organization.corporateNumber,
                        organization_name: organization.name,
                    });
                    break;
                }
                case 2: {
                    setValues({
                        corporate_number_2: organization.corporateNumber,
                        organization_name_2: organization.name,
                    });
                    break;
                }
                case 3: {
                    setValues({
                        corporate_number_3: organization.corporateNumber,
                        organization_name_3: organization.name,
                    });
                    break;
                }
            }
        }
        function getIndustries() {
            return masterInteractor.getIndustries().then(function (result) {
                if (!result.data) {
                    return;
                }
                state.listIndustry = result.data;
            });
        }
        function getOccupations() {
            return masterInteractor.getOccupations().then(function (result) {
                if (!result.data) {
                    return;
                }
                state.listOccupation = result.data;
            });
        }
        function selectedIndustry(industry) {
            return state.userProfileInfo.industries.filter(function (selected) {
                return selected.id === industry.id;
            }).length > 0;
        }
        function selectedOccupation(occupation) {
            return state.userProfileInfo.occupations.filter(function (selected) {
                return selected.id === occupation.id;
            }).length > 0;
        }
        function selectedAvailableDatetime(available_datetime) {
            return state.userProfileInfo.available_datetimes.filter(function (selected) {
                return selected.id === available_datetime.id;
            }).length > 0;
        }
        function checkIndustry(event, industry) {
            var industries = values.industries;
            if (event.target.checked) {
                industries.push(industry);
            }
            else {
                industries = industries.filter(function (checked) {
                    return checked.id != industry.id;
                });
            }
            setValues({ industries: industries });
        }
        function checkOccupation(event, occupation) {
            var occupations = values.occupations;
            if (event.target.checked) {
                occupations.push(occupation);
            }
            else {
                occupations = occupations.filter(function (checked) {
                    return checked.id != occupation.id;
                });
            }
            setValues({ occupations: occupations });
        }
        var enableNoWorkPlaceReason = computed(function () {
            return values.corporate_number === state.corporateNumberNoWorkPlace
                || values.corporate_number_2 === state.corporateNumberNoWorkPlace
                || values.corporate_number_3 === state.corporateNumberNoWorkPlace;
        });
        watch(function () { return enableNoWorkPlaceReason.value; }, function (enable) {
            if (!enable) {
                values.reason_no_work_place = "";
            }
        });
        onMounted(function () {
            getIndustries();
            getOccupations();
        });
        return {
            state: state,
            values: values,
            errors: errors,
            onSubmit: onSubmit,
            enableNoWorkPlaceReason: enableNoWorkPlaceReason,
            onUnselectedOrganization: onUnselectedOrganization,
            onSelectOrganization: onSelectOrganization,
            selectedIndustry: selectedIndustry,
            selectedOccupation: selectedOccupation,
            selectedAvailableDatetime: selectedAvailableDatetime,
            checkIndustry: checkIndustry,
            checkOccupation: checkOccupation,
            nowYear: nowYear,
        };
    }
});
