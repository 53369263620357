<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-body">
                        <slot name="body">
                            <div class="profile_info__edit__container">
                                <div class="profile_info__edit__container-title">
                                    {{ state.title || "編集中" }}
                                </div>
                                <form
                                    class="horizontal"
                                    @submit="onSubmit"
                                >
                                    <div class="form-ctrl" v-if="state.field == 'name'">
                                        <label for="name" class="text">氏名</label>
                                        <div class="form-group">
                                            <div class="form-el">
                                                <Input
                                                    maxlength="64"
                                                    type="text"
                                                    placeholder="苗字を入力してください"
                                                    v-model="values.family_name"
                                                />
                                                <ErrorMessage
                                                    v-if="errors.family_name"
                                                    :message="errors.family_name"
                                                />
                                                <template
                                                    v-if="state.errorValidateMessage.family_name"
                                                >
                                                    <ErrorMessage
                                                        v-for="m of state.errorValidateMessage.family_name"
                                                        :key="m"
                                                        :message="m"
                                                    >
                                                    </ErrorMessage>
                                                </template>
                                            </div>
                                            <div class="form-el">
                                                <Input
                                                    maxlength="64"
                                                    type="text"
                                                    placeholder="名前を入力してください"
                                                    v-model="values.given_name"
                                                />
                                                <ErrorMessage
                                                    v-if="errors.given_name"
                                                    :message="errors.given_name"
                                                />
                                                <template
                                                    v-if="state.errorValidateMessage.given_name"
                                                >
                                                    <ErrorMessage
                                                        v-for="m of state.errorValidateMessage.given_name"
                                                        :key="m"
                                                        :message="m"
                                                    >
                                                    </ErrorMessage>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-ctrl" v-if="state.field == 'name_kana'">
                                        <label for="name_kana" class="text">（氏名）カナ</label>
                                        <div class="form-group">
                                            <div class="form-el">
                                                <Input
                                                    maxlength="64"
                                                    type="text"
                                                    placeholder="苗字カナを入力してください"
                                                    v-model="values.family_name_kana"
                                                />
                                                <ErrorMessage
                                                    v-if="errors.family_name_kana"
                                                    :message="errors.family_name_kana"
                                                />
                                                <template
                                                    v-if="state.errorValidateMessage.family_name_kana"
                                                >
                                                    <ErrorMessage
                                                        v-for="m of state.errorValidateMessage.family_name_kana"
                                                        :key="m"
                                                        :message="m"
                                                    >
                                                    </ErrorMessage>
                                                </template>
                                            </div>
                                            <div class="form-el">
                                                <Input
                                                    maxlength="64"
                                                    type="text"
                                                    placeholder="名前カナを入力してください"
                                                    v-model="values.given_name_kana"
                                                />
                                                <ErrorMessage
                                                    v-if="errors.given_name_kana"
                                                    :message="errors.given_name_kana"
                                                />
                                                <template
                                                    v-if="state.errorValidateMessage.given_name_kana"
                                                >
                                                    <ErrorMessage
                                                        v-for="m of state.errorValidateMessage.given_name_kana"
                                                        :key="m"
                                                        :message="m"
                                                    >
                                                    </ErrorMessage>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-ctrl" v-if="state.field == 'industries'">
                                        <label class="text">協働で担当可能な、知見がある業種を選択してください。</label>
                                        <div class="form-checkbox-group">
                                            <div class="checkbox" v-for="(industry, i) in state.listIndustry" :key="i">
                                                <input
                                                    :id="`industry_${industry.id}`"
                                                    name="industries"
                                                    type="checkbox"
                                                    :value="industry.id"
                                                    :checked="selectedIndustry(industry)"
                                                    @change="checkIndustry($event, industry)"
                                                />
                                                <label :for="`industry_${industry.id}`">{{ industry.name }}</label>
                                            </div>
                                            <ErrorMessage
                                                v-if="errors.industries"
                                                :message="errors.industries"
                                            />
                                            <template
                                                v-if="state.errorValidateMessage.industries"
                                            >
                                                <ErrorMessage
                                                    v-for="m of state.errorValidateMessage.industries"
                                                    :key="m"
                                                    :message="m"
                                                >
                                                </ErrorMessage>
                                            </template>
                                        </div>
                                        <div class="form-checkbox-optional">
                                            <label class="text">その他</label>
                                            <div class="form-group">
                                                <Textarea
                                                    name="industry_optional"
                                                    maxlength="10000"
                                                    placeholder="選択肢にない業種や、より詳細な業種の情報があればご記入ください。"
                                                    v-model="values.industry_optional"
                                                />
                                                <ErrorMessage
                                                    v-if="errors.industry_optional"
                                                    :message="errors.industry_optional"
                                                />
                                                <template
                                                    v-if="state.errorValidateMessage.industry_optional"
                                                >
                                                    <ErrorMessage
                                                        v-for="m of state.errorValidateMessage.industry_optional"
                                                        :key="m"
                                                        :message="m"
                                                    >
                                                    </ErrorMessage>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-ctrl" v-if="state.field == 'occupations'">
                                        <label class="text">対応可能な役割を選択してください。</label>
                                        <div class="form-checkbox-group">
                                            <div class="checkbox" v-for="(occupation, i) in state.listOccupation" :key="i">
                                                <input
                                                    :id="`occupation_${occupation.id}`"
                                                    name="occupations"
                                                    type="checkbox"
                                                    :value="occupation.id"
                                                    :checked="selectedOccupation(occupation)"
                                                    @change="checkOccupation($event, occupation)"
                                                />
                                                <label :for="`occupation_${occupation.id}`">{{ occupation.name }}</label>
                                            </div>
                                            <ErrorMessage
                                                v-if="errors.occupations"
                                                :message="errors.occupations"
                                            />
                                            <template
                                                v-if="state.errorValidateMessage.occupations"
                                            >
                                                <ErrorMessage
                                                    v-for="m of state.errorValidateMessage.occupations"
                                                    :key="m"
                                                    :message="m"
                                                >
                                                </ErrorMessage>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="form-ctrl" v-if="state.field == 'alma_mater'">
                                        <label for="alma_mater" class="text">学校名</label>
                                        <div class="form-group">
                                            <Input
                                                maxlength="255"
                                                type="text"
                                                placeholder="入力してください"
                                                v-model="values.alma_mater"
                                            />
                                            <ErrorMessage
                                                v-if="errors.alma_mater"
                                                :message="errors.alma_mater"
                                            />
                                            <template
                                                v-if="state.errorValidateMessage.alma_mater"
                                            >
                                                <ErrorMessage
                                                    v-for="m of state.errorValidateMessage.alma_mater"
                                                    :key="m"
                                                    :message="m"
                                                >
                                                </ErrorMessage>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="form-ctrl" v-if="state.field == 'academy_department'">
                                        <label for="academy_department" class="text">学部・研究科名</label>
                                        <div class="form-group">
                                            <Input
                                                maxlength="255"
                                                type="text"
                                                placeholder="入力してください"
                                                v-model="values.academy_department"
                                            />
                                            <ErrorMessage
                                                v-if="errors.academy_department"
                                                :message="errors.academy_department"
                                            />
                                            <template
                                                v-if="state.errorValidateMessage.academy_department"
                                            >
                                                <ErrorMessage
                                                    v-for="m of state.errorValidateMessage.academy_department"
                                                    :key="m"
                                                    :message="m"
                                                >
                                                </ErrorMessage>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="form-ctrl" v-if="state.field == 'graduation_year'">
                                      <label for="graduation_year" class="text">卒業年度</label>
                                      <div class="form-group">
                                        <select v-model="values.graduation_year">
                                          <option value=""></option>
                                          <option value="1">在学中</option>
                                          <option
                                              v-for="year in 50"
                                              :key="nowYear - year + 1"
                                              :value="nowYear - year + 1"
                                          >
                                            {{ nowYear - year + 1 }}年度
                                          </option>
                                        </select>
                                        <ErrorMessage
                                            v-if="errors.graduation_year"
                                            :message="errors.graduation_year"
                                        />
                                        <template
                                            v-if="state.errorValidateMessage.graduation_year"
                                        >
                                          <ErrorMessage
                                              v-for="m of state.errorValidateMessage.graduation_year"
                                              :key="m"
                                              :message="m"
                                          >
                                          </ErrorMessage>
                                        </template>
                                      </div>
                                    </div>
                                    <div class="form-ctrl" v-if="state.field == 'work_place'">
                                        <div v-if="societyType==='社会人'">
                                            <p class="message">
                                                現在のお勤務先企業をご入力ください。1社は必須となります。
                                            </p>
                                            <div class="attention">
                                                ※1社は必須です
                                                <br/>
                                                ※現在お勤めの企業のみご記入ください。
                                                <br/>
                                                ※現在の勤務先、または業務委託中の企業が複数ある場合は最大3社までご入力可能です。
                                                <br/>
                                                ※3社を超える勤務先・業務委託先がある場合には事務局までご連絡ください。
                                            </div>
                                        </div>
                                        <div class="organization-group">
                                            <label class="text">勤務先1<span v-if="societyType==='社会人'" class="alert">(必須)</span></label>
                                            <div>
                                                <div class="form-item">
                                                    <SelectOrganization
                                                        id="organization_name"
                                                        name="organization_name"
                                                        v-model="values.organization_name"
                                                        :initOrganizationName="state.userProfileInfo.organization_name"
                                                        :initCorporateNumber="state.userProfileInfo.corporate_number"
                                                        :excludeCorporateNumbers="[values.corporate_number_2, values.corporate_number_3]"
                                                        @selected="onSelectOrganization($event, 1)"
                                                        @unselected="onUnselectedOrganization(1)"
                                                    />
                                                </div>
                                                <ErrorMessage
                                                    v-if="errors.organization_name"
                                                    :message="errors.organization_name"
                                                />
                                                <template
                                                    v-if="state.errorValidateMessage.organization_name"
                                                >
                                                    <ErrorMessage
                                                        v-for="m of state.errorValidateMessage.organization_name"
                                                        :key="m"
                                                        :message="m"
                                                    >
                                                    </ErrorMessage>
                                                </template>
                                            </div>
                                            <label class="text">勤務先2</label>
                                            <div>
                                                <div class="form-item">
                                                    <SelectOrganization
                                                        id="organization_name_2"
                                                        name="organization_name_2"
                                                        v-model="values.organization_name_2"
                                                        :initOrganizationName="state.userProfileInfo.organization_name_2"
                                                        :initCorporateNumber="state.userProfileInfo.corporate_number_2"
                                                        :excludeCorporateNumbers="[values.corporate_number, values.corporate_number_3]"
                                                        @selected="onSelectOrganization($event, 2)"
                                                        @unselected="onUnselectedOrganization(2)"
                                                    />
                                                </div>
                                                <ErrorMessage
                                                    v-if="errors.organization_name_2"
                                                    :message="errors.organization_name_2"
                                                />
                                                <template
                                                    v-if="state.errorValidateMessage.organization_name_2"
                                                >
                                                    <ErrorMessage
                                                        v-for="m of state.errorValidateMessage.organization_name_2"
                                                        :key="m"
                                                        :message="m"
                                                    >
                                                    </ErrorMessage>
                                                </template>
                                            </div>
                                            <label class="text">勤務先3</label>
                                            <div>
                                                <div class="form-item">
                                                    <SelectOrganization
                                                        id="organization_name_3"
                                                        name="organization_name_3"
                                                        v-model="values.organization_name_3"
                                                        :initOrganizationName="state.userProfileInfo.organization_name_3"
                                                        :initCorporateNumber="state.userProfileInfo.corporate_number_3"
                                                        :excludeCorporateNumbers="[values.corporate_number, values.corporate_number_2]"
                                                        @selected="onSelectOrganization($event, 3)"
                                                        @unselected="onUnselectedOrganization(3)"
                                                    />
                                                </div>
                                                <ErrorMessage
                                                    v-if="errors.organization_name_3"
                                                    :message="errors.organization_name_3"
                                                />
                                                <template
                                                    v-if="state.errorValidateMessage.organization_name_3"
                                                >
                                                    <ErrorMessage
                                                        v-for="m of state.errorValidateMessage.organization_name_3"
                                                        :key="m"
                                                        :message="m"
                                                    >
                                                    </ErrorMessage>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-ctrl" v-if="state.field == 'profile_description'">
                                        <label for="profile_description" class="text">自己紹介</label>
                                        <div class="form-group">
                                            <Textarea
                                                maxlength="10000"
                                                placeholder="入力してください"
                                                v-model="values.profile_description"
                                            />
                                            <ErrorMessage
                                                v-if="errors.profile_description"
                                                :message="errors.profile_description"
                                            />
                                            <template
                                                v-if="state.errorValidateMessage.profile_description"
                                            >
                                                <ErrorMessage
                                                    v-for="m of state.errorValidateMessage.profile_description"
                                                    :key="m"
                                                    :message="m"
                                                >
                                                </ErrorMessage>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="form-ctrl" v-if="state.field == 'public_relations_description'">
                                        <label for="public_relations_description" class="text">PR / 特技</label>
                                        <div class="form-group">
                                            <Textarea
                                                maxlength="10000"
                                                placeholder="入力してください"
                                                v-model="values.public_relations_description"
                                            />
                                            <ErrorMessage
                                                v-if="errors.public_relations_description"
                                                :message="errors.public_relations_description"
                                            />
                                            <template
                                                v-if="state.errorValidateMessage.public_relations_description"
                                            >
                                                <ErrorMessage
                                                    v-for="m of state.errorValidateMessage.public_relations_description"
                                                    :key="m"
                                                    :message="m"
                                                >
                                                </ErrorMessage>
                                            </template>
                                        </div>
                                    </div>

                                    <ErrorMessage
                                        v-if="
                                            state.errorUpdateUserProfileInfoMessage
                                        "
                                        :message="
                                            state.errorUpdateUserProfileInfoMessage
                                        "
                                    />

                                    <div
                                        class="profile_info__edit__container-button"
                                    >
                                        <button
                                            @click="$emit('close')"
                                            type="button"
                                            class="btn-light-gray btn-md sweep-to-top"
                                        >
                                            キャンセル
                                        </button>
                                        <button
                                            :disabled="state.isSubmitting"
                                            :class="{
                                                submitting: state.isSubmitting
                                            }"
                                            type="submit"
                                            class="btn-blue btn-md sweep-to-top"
                                        >
                                            <i
                                                v-if="state.isSubmitting"
                                                class="fas fa-circle-notch fa-spin"
                                            ></i>
                                            編集完了
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/user-profile-edit-info/UserProfileEditInfo.ts" />
<link type="scss" scoped src="@/presentation/components/modals/user-profile-edit-info/UserProfileEditInfo.scss" />
