import { defineComponent, ref, reactive, onMounted, computed } from "vue";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import UserInteractor from "@/domain/usecases/UserInteractor";
import DIContainer from "@/core/DIContainer";
import Input from "@/presentation/components/input/Input.vue";
import http from "@/data/datasource/api/Interceptors";
export default defineComponent({
    name: "UserProfileEditInfoPopup",
    components: {
        Textarea: Textarea,
        ErrorMessage: ErrorMessage,
        Input: Input
    },
    props: [
        "dataUrl",
    ],
    setup: function (props, _a) {
        var emit = _a.emit;
        var state = reactive({
            isSubmitting: false,
            isDefaultEmpty: false,
            defaultDataUrl: null,
            fileData: null,
            error: null,
        });
        var userInteractor = DIContainer.instance.get(UserInteractor);
        var fileInputRef = ref(null);
        var submitDisabled = computed(function () { return state.isSubmitting || state.defaultDataUrl || state.isDefaultEmpty && !state.fileData; });
        var handleUpload = function (e) {
            var _a, _b, _c, _d;
            state.error = null;
            if (state.isSubmitting) {
                return;
            }
            var file = ((_b = (_a = e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0]) || ((_d = (_c = e.dataTransfer) === null || _c === void 0 ? void 0 : _c.files) === null || _d === void 0 ? void 0 : _d[0]);
            if (!file) {
                return;
            }
            if (file.type !== 'application/pdf') {
                alert('PDFファイルをご指定ください。');
                return;
            }
            if (file.size && (file.size / 1024 / 1024) > 30) {
                alert('30MB以内のファイルをご指定ください。');
                return;
            }
            state.fileData = file;
            fileInputRef.value.value = "";
            state.defaultDataUrl = null;
        };
        var handleUploadError = function () {
            alert('送信エラーが起きました。お手数ですが、再度やり直してください。');
            state.isSubmitting = false;
        };
        var handleDelete = function () {
            state.fileData = null;
            state.defaultDataUrl = null;
        };
        var handleSubmit = function () {
            state.isSubmitting = true;
            state.error = null;
            if (state.fileData) {
                userInteractor
                    .getPreSignedUrlPr()
                    .then(function (res) {
                    http({
                        method: res.data.method,
                        url: res.data.url,
                        data: state.fileData
                    }).then(function (res) {
                        state.isSubmitting = false;
                        emit('complete', true);
                        emit('close');
                    }, function (error) { return handleUploadError(); });
                }, function (error) { return handleUploadError(); });
            }
            else if (!state.isDefaultEmpty) {
                userInteractor
                    .deleteFilesUserPr()
                    .then(function (result) {
                    state.isSubmitting = false;
                    emit('complete', false);
                    emit('close');
                }, function (error) { return handleUploadError(); });
            }
        };
        onMounted(function () {
            state.defaultDataUrl = props.dataUrl;
            state.isDefaultEmpty = !props.dataUrl;
        });
        return {
            state: state,
            handleUpload: handleUpload,
            handleUploadError: handleUploadError,
            handleDelete: handleDelete,
            handleSubmit: handleSubmit,
            fileInputRef: fileInputRef,
            submitDisabled: submitDisabled
        };
    }
});
