import { computed, defineComponent, onMounted, reactive } from "vue";
import Input from "@/presentation/components/input/Input.vue";
import MyConfig from "@/_config";
import MasterInteractor from "@/domain/usecases/MasterInteractor";
import DIContainer from "@/core/DIContainer";
export default defineComponent({
    name: "SelectOrganization",
    components: {
        Input: Input,
    },
    props: {
        id: String,
        name: String,
        initOrganizationName: String,
        initCorporateNumber: String,
        isSearch: Boolean,
        excludeCorporateNumbers: {
            type: Array,
            required: false,
            default: null,
        }
    },
    emits: {
        selected: Object,
        unselected: null,
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var state = reactive({
            listCorporations: [],
            showDropdownList: false,
            loadingDataDropdown: false,
            corporateNumberNoWorkPlace: MyConfig.corporateNumberNoWorkPlace,
            organizationName: "",
            corporateNumber: null,
        });
        var masterInteractor = DIContainer.instance.get(MasterInteractor);
        var isSearch = computed(function () { return props.isSearch; });
        var timeout = null;
        function setDefaultListCorporations() {
            if (timeout) {
                clearTimeout(timeout);
            }
            var cb = function () {
                if (!isSearch.value &&
                    (!props.excludeCorporateNumbers || !props.excludeCorporateNumbers.includes(state.corporateNumberNoWorkPlace))) {
                    state.listCorporations.unshift({
                        corporateNumber: state.corporateNumberNoWorkPlace,
                        name: "勤務先なし",
                        prefectureName: "-",
                        cityName: "",
                        streetNumber: "",
                    });
                }
            };
            state.showDropdownList = true;
            state.loadingDataDropdown = true;
            timeout = setTimeout(function () {
                getListCorporations("あ", cb);
            }, 500);
        }
        function onInput(event) {
            state.corporateNumber = null;
            if (timeout) {
                clearTimeout(timeout);
            }
            if (!event.target.value) {
                setDefaultListCorporations();
                return;
            }
            state.showDropdownList = true;
            state.loadingDataDropdown = true;
            timeout = setTimeout(function () {
                getListCorporations(event.target.value);
            }, 500);
        }
        function onFocus() {
            if (state.listCorporations.length == 0) {
                setDefaultListCorporations();
            }
            else {
                state.showDropdownList = true;
            }
        }
        function onOutFocus() {
            if (timeout) {
                clearTimeout(timeout);
            }
            if (!state.corporateNumber) {
                state.organizationName = "";
                state.listCorporations = [];
                emit("unselected", null);
            }
            state.showDropdownList = false;
        }
        function onSelectOrganization(organization) {
            state.corporateNumber = organization.corporateNumber || null;
            state.organizationName = organization.name || "";
            state.listCorporations = [];
            state.showDropdownList = false;
            emit("selected", organization);
        }
        function getListCorporations(keyword, cb) {
            if (keyword === void 0) { keyword = ""; }
            if (!keyword) {
                return;
            }
            return masterInteractor.searchCorporations(keyword).then(function (result) {
                state.loadingDataDropdown = false;
                if (!result.results || (result.results && result.results.length == 0)) {
                    state.listCorporations = [];
                    return;
                }
                if (props.excludeCorporateNumbers) {
                    state.listCorporations = result.results.filter(function (organization) { return !props.excludeCorporateNumbers.includes(organization.corporateNumber); });
                }
                else {
                    state.listCorporations = result.results;
                }
                if (cb) {
                    cb();
                }
            }).catch(function () {
                state.loadingDataDropdown = false;
            });
        }
        onMounted(function () {
            if (props.initOrganizationName && props.initCorporateNumber) {
                state.organizationName = props.initOrganizationName;
                state.corporateNumber = props.initCorporateNumber;
            }
        });
        return {
            state: state,
            isSearch: isSearch,
            onInput: onInput,
            onFocus: onFocus,
            onOutFocus: onOutFocus,
            onSelectOrganization: onSelectOrganization,
        };
    }
});
