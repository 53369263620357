<template>
  <div class="select-organization" v-click-outside="onOutFocus">
    <Input
        :id="id"
        :name="name"
        :maxlength="isSearch ? undefined : 255"
        :placeholder="isSearch ? '企業名で検索' : 'ご入力ください'"
        :class="{is_search_text: isSearch}"
        v-model="state.organizationName"
        type="text"
        @keyup="onInput"
        autocomplete="off"
        @focus="onFocus(1)"
    />
    <div v-if="state.showDropdownList" class="dropdown-list">
      <template
          v-if="state.listCorporations && !state.loadingDataDropdown"
      >
        <a
            v-for="organization in state.listCorporations"
            :key="organization.corporateNumber"
            @click="onSelectOrganization(organization)"
        >{{
            organization.name + " (" + organization.prefectureName + organization.cityName + organization.streetNumber + ")"
          }}</a>
      </template>
      <p v-if="state.listCorporations.length === 0 && !state.loadingDataDropdown">検索結果のデータがありません。</p>
      <p v-if="state.loadingDataDropdown">Loading...</p>
    </div>
  </div>
</template>

<script lang="ts" src="./SelectOrganization.ts"/>
<style lang="scss" src="./SelectOrganization.scss" scoped/>