<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-body">
                        <slot name="body">
                            <!-- modal content -->
                            <div class="profile_info__edit__container">
                                <div class="profile_info__edit__container-title">
                                   プロフィールレジュメ
                                </div>
                                <div
                                    class="profile_info__edit__container-form"
                                >
                                    <div class="form-ctrl">
                                        <span v-if="state.defaultDataUrl" class="uploaded-text">
                                            <i class="far fa-file"></i>
                                            ファイルアップロード済み
                                        </span>
                                        <span v-else-if="state.fileData" class="uploaded-text">
                                            <i class="far fa-file"></i>
                                            {{ state.fileData.name }}
                                        </span>
                                        <span v-else class="no-data">
                                            ファイルがありません
                                        </span>
                                        <ErrorMessage
                                            v-if="state.error"
                                            :message="state.error"
                                        />
                                    </div>

                                    <div
                                        class="profile_info__edit__container-button"
                                    >
                                        <button
                                            :disabled="state.isSubmitting"
                                            @click="!$emit('close')"
                                            type="button"
                                            class="btn btn-light-gray btn-md sweep-to-top is-cancel"
                                        >
                                            キャンセル
                                        </button>
                                        <button
                                            :disabled="state.isSubmitting"
                                            :class="{
                                                submitting: state.isSubmitting
                                            }"
                                            @click="handleDelete"
                                            class="btn btn-blue btn-md sweep-to-top is-delete"
                                        >
                                            削除
                                        </button>
                                        <input
                                            type="file"
                                            id="upload-file"
                                            @error="handleUploadError"
                                            @change="handleUpload"
                                            ref="fileInputRef"
                                            accept="application/pdf"
                                        />
                                        <label
                                            :class="{
                                                submitting: state.isSubmitting
                                            }"
                                            for="upload-file"
                                            class="btn btn-blue btn-md sweep-to-top is-upload"
                                            @change="!state.isSubmitting && handleFileChange"
                                        >
                                            PDFファイルを選択
                                        </label>
                                        <button
                                            :disabled="submitDisabled"
                                            :class="{
                                                submitting: submitDisabled
                                            }"
                                            @click="handleSubmit"
                                            class="btn btn-blue btn-md sweep-to-top is-submit"
                                        >
                                            <i
                                                v-if="state.isSubmitting"
                                                class="fas fa-circle-notch fa-spin"
                                            ></i>
                                            編集完了
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!-- / modal content -->
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script lang="ts" src="./UserResumeUpload.ts" />
<link type="scss" scoped src="./UserResumeUpload.scss" />
