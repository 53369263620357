import { reactive, defineComponent, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import StringUtils from "@/commons/StringUtils";
import UserProfileEditInfoPopup from "@/presentation/components/modals/user-profile-edit-info/UserProfileEditInfo.vue";
import UserResumeUploadPopup from "@/presentation/components/modals/user-resume-upload/UserResumeUpload.vue";
import UserInteractor from "@/domain/usecases/UserInteractor";
import DIContainer from "@/core/DIContainer";
import CommonService from "@/services/CommonService";
import ProfilePreload from "@/presentation/components/preloader/profile-preload/ProfilePreload.vue";
import Preloaders from '@/presentation/components/preloader/Preloaders.vue';
import MyConfig from '@/_config';
import Constant from '@/commons/Constant';
export default defineComponent({
    name: "UserProfileInfo",
    components: {
        UserProfileEditInfoPopup: UserProfileEditInfoPopup,
        UserResumeUploadPopup: UserResumeUploadPopup,
        ProfilePreload: ProfilePreload,
        Preloaders: Preloaders
    },
    props: ["breadcrumb", "alert", "preload"],
    setup: function (props) {
        var store = useStore();
        var router = useRouter();
        var config = MyConfig;
        var route = useRoute();
        var state = reactive({
            showEditPopup: false,
            showUploadPopup: false,
            editField: "",
            editTitle: "",
            userProfileInfo: store.state.userProfileInfo || {},
            isOwner: false,
            serializeForm: {},
            resumeLoading: false,
            corporateNumberNoWorkPlace: MyConfig.corporateNumberNoWorkPlace,
        });
        var userInteractor = DIContainer.instance.get(UserInteractor);
        function openEditModal(field, title) {
            state.showEditPopup = true;
            state.editField = field;
            state.editTitle = title;
            state.serializeForm = serializeForm();
        }
        function serializeForm() {
            var formInput = document.querySelectorAll('input[type="checkbox"]');
            var formData = {
                is_public_prefecture: false,
                is_public_language: false,
                is_public_degree: false,
                is_public_graduation: false,
                is_public_society_type: false,
                family_name: state.userProfileInfo.family_name,
                given_name: state.userProfileInfo.given_name,
                family_name_kana: state.userProfileInfo.family_name_kana,
                given_name_kana: state.userProfileInfo.given_name_kana,
                alma_mater: state.userProfileInfo.alma_mater,
                academy_department: state.userProfileInfo.academy_department,
                graduation_year: state.userProfileInfo.graduation_year,
                corporate_number: state.userProfileInfo.corporate_number,
                organization_name: state.userProfileInfo.organization_name,
                corporate_number_2: state.userProfileInfo.corporate_number_2,
                organization_name_2: state.userProfileInfo.organization_name_2,
                corporate_number_3: state.userProfileInfo.corporate_number_3,
                organization_name_3: state.userProfileInfo.organization_name_3,
                profile_description: state.userProfileInfo.profile_description,
                public_relations_description: state.userProfileInfo.public_relations_description,
                tutorial_complete_at: state.userProfileInfo.tutorial_complete_at,
                society_type: state.userProfileInfo.society_type,
            };
            formInput.forEach(function (input) {
                formData[input.name] = input.checked;
            });
            return formData;
        }
        function updatePublicState() {
            var formData = serializeForm();
            userInteractor
                .updateUserProfile(formData)
                .then(function (result) {
                store.commit("setUserProfileData", Object.assign(state.userProfileInfo, formData));
            }, function (error) {
            });
        }
        function handleResumeUpdate(isExistFile) {
            if (isExistFile) {
                state.resumeLoading = true;
                userInteractor
                    .getUserProfile(parseInt(route.params.userId))
                    .then(function (result) {
                    state.userProfileInfo.pr_file_url = result.data.pr_file_url;
                    state.resumeLoading = false;
                }, function (error) {
                    state.resumeLoading = false;
                });
            }
            else {
                state.userProfileInfo.pr_file_url = null;
            }
        }
        function graduationYear() {
            if (state.userProfileInfo.graduation_year == 1) {
                return '在学中';
            }
            else if (state.userProfileInfo.graduation_year) {
                return state.userProfileInfo.graduation_year + '年度';
            }
            else {
                return '情報がありません';
            }
        }
        onMounted(function () {
            store.commit("checkReadGuideScreen", Constant.GUIDE_USER_PROFILE);
            props.breadcrumb[2] = { name: "基本情報", route: "" };
            store.subscribe(function (mutation, stateVuex) {
                if (mutation.type === "setUserProfileData") {
                    state.userProfileInfo = stateVuex.userProfileInfo;
                }
            });
            var user = CommonService.getCookie("user");
            var user_infor = {};
            if (user) {
                user_infor = JSON.parse(decodeURIComponent(user));
                state.isOwner = user_infor.account_id == Number(route.params.userId);
            }
        });
        return {
            state: state,
            config: config,
            StringUtils: StringUtils,
            props: props,
            openEditModal: openEditModal,
            updatePublicState: updatePublicState,
            handleResumeUpdate: handleResumeUpdate,
            graduationYear: graduationYear,
        };
    }
});
